import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_Card = _resolveComponent("Card");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "调查问卷",
    back: true
  }), _ctx.Data.isCard ? (_openBlock(), _createBlock(_component_Card, {
    key: 0,
    list: _ctx.Data.detail,
    record: _ctx.Data.recordId,
    state: _ctx.Data.state,
    onGetCardChildren: _ctx.getCardChildren
  }, null, 8, ["list", "record", "state", "onGetCardChildren"])) : _createCommentVNode("", true)], 64);
}